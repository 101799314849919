<template>
  <div>
    <HeaderNav :auth="false" />

    <div class="wrapper startView">
      <div class="container">
        <div class="title__wrap">
          <!-- content.startMainTitle -->
          <h1>
            Регистрируй чек <br />
            для участия в акции!
          </h1>

          <!-- content.startSubTitle -->
          <p>
            Выигрывай крутые призы каждую неделю! Главный приз — ааааааавтомобиль!
          </p>
        </div>

        <!-- content.startPrimaryButton -->
        <v-btn
          depressed
          color="primary"
          class="next-btn start"
          @click="$router.push({ name: 'login' }).catch(() => {})"
          >Начать</v-btn
        >
        <!-- content.startSecondaryButton -->
        <a class="about-btn" :href="promoCDN + `/pdf/rules.pdf`" target="_blank"
          >Условия акции</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import HeaderNav from '@/components/HeaderNav.vue';

export default {
  data() {
    return {
      promoCDN: process.env.VUE_APP_PROMO_CDN,
    };
  },
  components: { HeaderNav },
  methods: {
    pushInPromo(link) {
      if (link === 'TermsPromo') {
        sessionStorage.setItem('lastPath', 'profile');
      }

      this.$router.push({ name: link }).catch(() => {});
    },
  },
};
</script>

<style>
.password-error {
  display: none;
  font-weight: 400;
  font-size: 12px !important;
  text-align: left;
  color: #db4100;
  letter-spacing: -0.015em;
  margin-top: -16px;
}

.recovery {
  font-family: 'Inter';
  margin-top: -16px !important;
  font-size: 12px;
  color: #4f70e4;
  font-weight: 500;
  cursor: pointer;
}

.wrapper {
  width: 100%;
  margin: 88px 0 40px 0;
  padding: 0 16px;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 0px;
  background: var(--block-color);
  width: 100%;
  padding: 32px 20px;
  border-radius: 8px;
}
.container h1 {
  font-family: 'PT Sans';
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
  letter-spacing: -0.005em;
  text-align: center;
}

.v-form,
.form {
  display: flex;
  flex-direction: column;
  /* gap: 32px !important; */
}

.v-form {
  background: var(--block-color);
  width: 100%;
  border-radius: 16px;
}

.next-btn {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

.v-btn:not(.v-btn--round).v-size--default.about-btn {
  background: none !important;
  color: var(--main-color) !important;
  font-weight: 700;
  font-size: 16px;
  border: 1.5px solid var(--main-color) !important;
  border-radius: var(--border-radius);
  line-height: 24px;
}
.v-btn:not(.v-btn--round).v-size--default.about-btn .v-btn__content {
  color: var(--main-color) !important;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}
.about-btn {
  background: none !important;
  color: var(--main-color) !important;
  font-weight: 700;
  font-size: 16px;
  border: 1.5px solid var(--main-color) !important;
  border-radius: var(--border-radius);
  line-height: 24px;
  text-decoration: none;
  padding: 8px;
}

.startView .about-btn {
  margin-top: 0 !important;
}

.v-text-field__slot {
  height: 44px;
}

.title__wrap {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 30px;
}

.title__wrap h1 {
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 110%;
  text-align: center;
  letter-spacing: -0.1px;

  color: #1a1a1a;
}

.title__wrap p {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  text-align: center;
  letter-spacing: -0.21px;

  color: #1a1a1a;
}

.v-icon.v-icon.v-icon--link {
  color: #1a1a1a !important;
  font-size: 20px !important;
}
.check .v-icon.v-icon.v-icon--link {
  color: #cccccc !important;
  font-size: 20px !important;
}

.v-text-field--outlined fieldset {
  border-radius: 6px;
  border-color: #cccccc !important;
}

fieldset.valid {
  border-color: #51ad30 !important;
}

fieldset.err {
  border-color: #db4100 !important;
}

.v-btn {
  margin-top: 8px;
  height: 44px !important;
}
.next-btn.start {
  margin-top: 0;
  margin-bottom: 16px;
}
.v-btn--disabled {
  background: #cdd6ca;
  border-radius: var(--border-radius);
}

.v-btn__content {
  font-weight: 500;
  font-size: 16px !important;
  color: #fff;
  letter-spacing: -0.015em;
  text-transform: initial;
}

.theme--light.v-text-field--outlined:not(.v-input--is-focused):not(
    .v-input--has-state
  ):not(.v-input--is-disabled)
  > .v-input__control
  > .v-input__slot:focus
  fieldset,
.theme--light.v-text-field--outlined:not(.v-input--is-focused):not(
    .v-input--has-state
  ):not(.v-input--is-disabled)
  > .v-input__control
  > .v-input__slot:active
  fieldset {
  border-color: #1a1a1a !important;
}

.v-btn:not(.v-btn--round).v-size--default {
  background: var(--main-color) !important;
  border-radius: var(--border-radius);
}

.inputs {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.active {
  display: block;
}

.hidden {
  display: none;
}

.v-text-field--enclosed.v-input--dense:not(
    .v-text-field--solo
  ).v-text-field--outlined
  .v-input__append-inner {
  margin-top: 10px;
}

.reg__completed {
  margin-top: 16px;
  margin-bottom: 32px;
}

@media screen and (max-width: 4000px) and (min-width: 769px) {
  .wrapper {
    display: flex;
    justify-content: center;
    padding: 0 80px;
  }

  .container {
    padding: 80px 0;

    max-width: 800px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .title__wrap {
    max-width: 380px;

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .title__wrap h1 {
    font: 700 40px 'PT Sans';
    line-height: 110%; /* 44px */
    letter-spacing: -0.2px;
  }

  .title__wrap p {
    font: 400 18px 'Inter';
    line-height: 130%; /* 23.4px */
    letter-spacing: -0.27px;
  }

  .next-btn,
  .about-btn {
    max-width: 380px;
    width: 100%;
  }

  .about-btn {
    padding: 9px 0;
  }
}
</style>
